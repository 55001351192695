import './List.scss'
import { useNavigate } from 'react-router-dom'

const List = ({ items, customClickHandler }) => {
  const navigate = useNavigate()

  const clickHandler = (itemKey) => {
    if (customClickHandler) {
      customClickHandler(itemKey)
    } else {
      navigate(itemKey)
    }
  }

  return (
    <div className="list">
      {Array.isArray(items) &&
        items.map((i) => (
          <div key={i.key} className="item" onClick={() => clickHandler(i.key)}>
            <div>{i.label}</div>
          </div>
        ))}
    </div>
  )
}

export default List
