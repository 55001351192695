import TriggerBackup from '../TriggerBackup/TriggerBackup'
import './Layout.scss'
import Secure from './Secure/Secure.js'

const Layout = ({ children, authRequired = true }) => {
  return (
    <div id="layout">
      <Secure authRequired={authRequired}>
        <div>
          <div id="header">
            <div className="logo">DOCKER MANAGER</div>
            <div className="triggerBackupWrapper">
              <TriggerBackup />
            </div>
          </div>
          {children}
        </div>
      </Secure>
    </div>
  )
}
export default Layout
