import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { login } from '../../interface/auth'
import { setLocal } from '../../redux/action/local'
import props from '../../redux/props'
import Cookies from 'universal-cookie'

const { useState } = require('react')
const { default: Layout } = require('../Layout/Layout')

const Login = () => {
  const [apiKey, setApiKey] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cookies = new Cookies()

  const onClickLogin = async () => {
    if (apiKey) {
      const token = await login(apiKey)
      if (token) {
        dispatch(setLocal(props.TOKEN, token))
        cookies.set('token', token, { path: '/' })
        navigate('/')
      }
    }
  }

  return (
    <Layout authRequired={false}>
      <input type="text" placeholder="API Key" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
      <button onClick={onClickLogin}>login</button>
    </Layout>
  )
}

export default Login
