import fetch, { METHOD } from '../fetch'

export const login = async (apiKey) => {
  const { status, token } = await fetch(METHOD.POST, '/auth/login', {
    apiKey,
  })
  if (status && token) {
    return token
  }
  return false
}

export const resolveToken = async (token) => {
  const { status, isValid } = await fetch(METHOD.POST, '/auth/resolve-token', {
    token,
  })
  return status ? isValid : false
}
