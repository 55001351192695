import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import fetch, { METHOD } from '../../fetch'
import Layout from '../Layout/Layout'
import List from '../List/List'

const BackupHistory = () => {
  const { project_name } = useParams()
  const [history, setHistory] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    const getHistory = async () => {
      const { status, history } = await fetch(METHOD.GET, '/history', { token, project_name })
      if (status && Array.isArray(history)) {
        setHistory(history)
      } else {
        setError(true)
      }
      setLoading(false)
    }
    if (token && !history) getHistory()
  }, [history, project_name, token])

  return (
    <Layout>
      <div id="backupHistory">
        <div className="headline">{`Backup-History of project: ${project_name}`}</div>
        {!loading && !error && <List items={history} />}
        {loading && !error && <div className="loading">Loading data...</div>}
        {error && <div className="error">History could not be loaded.</div>}
      </div>
    </Layout>
  )
}

export default BackupHistory
