import { useEffect, useState } from 'react'
import fetch, { METHOD } from '../../fetch'
import './Home.scss'
import List from '../List/List'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'

const Home = () => {
  const [projects, setProjects] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    const getProjects = async () => {
      const { status, projects } = await fetch(METHOD.GET, '/projects', { token })
      if (status && Array.isArray(projects)) {
        setProjects(projects)
      } else {
        setError(true)
      }
      setLoading(false)
    }
    if (token && !projects) getProjects()
  }, [projects, token])

  return (
    <Layout>
      <div id="home">
        <div className="headline">Project Overview</div>
        {!loading && !error && (
          <div className="list">
            <List items={projects.map((p) => ({ key: p, label: p }))} />
          </div>
        )}
        {loading && !error && <div className="loading">Loading data...</div>}
        {error && <div className="error">Services could not be loaded.</div>}
      </div>
    </Layout>
  )
}

export default Home
