import { backendDomain } from './utility'
import axios from 'axios'

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
}

const fetch = async (method, uriSuffix, body = {}, overwriteURL = false) => {
  try {
    if (!uriSuffix) return false
    const result = await axios({
      method,
      url: overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`,
      [method === METHOD.GET ? 'params' : 'data']: body,
    })
    return result.data
  } catch (err) {
    return false
  }
}

export default fetch
