import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import fetch, { METHOD } from '../../fetch'
import Layout from '../Layout/Layout'
import List from '../List/List'
import { Modal, Button } from 'react-bootstrap'
import './Restore.scss'
import { useSelector } from 'react-redux'

const Restore = () => {
  const { project_name, backup } = useParams()
  const [backupList, setBackupList] = useState()
  const [showModal, setShowModal] = useState(false)
  const [status, setStatus] = useState('')
  const [key, setKey] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    const getBackups = async () => {
      const { status, list } = await fetch(METHOD.GET, '/backup/list', { project_name, backup, token })
      if (status && Array.isArray(list)) {
        setBackupList(list)
      } else {
        setError(true)
      }
      setLoading(false)
    }
    if (token && !backupList) getBackups()
  }, [backupList, project_name, backup, token])

  const restoreRequest = (key) => {
    setShowModal(true)
    setKey(key)
  }

  const restore = async () => {
    setStatus('Restore in Progress...')
    const { status } = await fetch(METHOD.GET, `/restore/${backup}/${project_name}`, { token })
    if (status) {
      setStatus('Restore finished')
      setShowModal(false)
      setKey(false)
    } else {
      setStatus('Restore failed!')
    }
  }

  const onHide = () => {
    setShowModal(false)
    setKey(null)
  }

  return (
    <Layout>
      <div id="restore">
        <div className="headline">{`Backups of ${project_name} on ${backup.slice(0, 10)}`}</div>
        {!loading && !error && <List items={backupList} customClickHandler={restoreRequest} />}
        {loading && !error && <div className="loading">Loading data...</div>}
        {error && <div className="error">History could not be loaded.</div>}
      </div>
      <Modal show={showModal} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Restore</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to restore the following backup?</p>
          <p>{key}</p>
          <h4>Status</h4>
          <p>{status}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            No
          </Button>
          <Button variant="primary" onClick={restore}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default Restore
