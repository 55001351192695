import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../fetch'
import './TriggerBackup.scss'

const TriggerBackup = () => {
  const [status, setStatus] = useState('')
  const [isRunning, setIsRunning] = useState(false)
  const [watchInterval, setWatchInterval] = useState(null)
  const [isInitialCall, setIsInitialCall] = useState(true)
  const token = useSelector((s) => s.token)

  useEffect(() => {
    const getState = async () => {
      const { backupRunning } = await fetch(METHOD.GET, '/backup/state', { token })
      if (backupRunning !== undefined) setIsRunning(backupRunning)
      setIsInitialCall(false)
    }

    const startWatcher = () => {
      const interval = setInterval(getState, 1000)
      setWatchInterval(interval)
    }

    if (isRunning) {
      setStatus('Backup in Progress ...')
      if (!watchInterval) startWatcher()
    } else {
      if (watchInterval) {
        clearInterval(watchInterval)
        setWatchInterval(null)
      }
      setStatus('Start Backup')
    }

    if (isInitialCall && token) {
      getState()
    }
  }, [isRunning, watchInterval, isInitialCall, token])

  const onClick = async () => {
    setIsRunning(true)
    const { status } = await fetch(METHOD.GET, '/backup/start', { token })
    if (status) {
      setIsRunning(true)
    } else setIsRunning(false)
  }

  return (
    <div className="btnTriggerBackup" onClick={onClick}>
      {status}
    </div>
  )
}

export default TriggerBackup
