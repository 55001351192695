import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { resolveToken } from '../../../interface/auth.js'
import props from '../../../redux/props'
import { setLocal } from '../../../redux/action/local'

const Secure = ({ children, authRequired = true }) => {
  const token = useSelector((state) => state[props.TOKEN])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const [isEvaluated, setIsEvaluated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    const token = cookies.get('token', { path: '/' })

    const evaluate = async () => {
      let isValid = false
      if (token) {
        isValid = await resolveToken(token)
      }
      if (isValid) {
        dispatch(setLocal(props.TOKEN, token))
        setIsEvaluated(true)
      } else if (authRequired) {
        cookies.remove('token', { path: '/' })
        dispatch(setLocal(props.TOKEN, null))
        navigate('/login')
      } else {
        setIsEvaluated(true)
      }
      setIsLoading(false)
    }

    if (!isEvaluated && !isLoading) {
      setIsLoading(true)
      evaluate()
    }
  }, [isEvaluated, authRequired, dispatch, navigate, token, pathname, searchParams, isLoading])

  return isEvaluated ? <>{children}</> : <></>
}

export default Secure
