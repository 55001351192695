import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.scss'
import BackupHistory from './components/BackupHistory/BackupHistory'
import Home from './components/Home/Home'
import Login from './components/Login/Login'
import Restore from './components/Restore/Restore'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/:project_name" element={<BackupHistory />} />
          <Route exact path="/:project_name/:backup" element={<Restore />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
